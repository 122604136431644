var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/productCase/banner-retail-1.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"60rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox",staticStyle:{"height":"auto","min-height":"15.3rem"}},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("某大型零售连锁企业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("应用环境复杂")]),_c('div',{staticClass:"content"},[_vm._v("全国拥有逾百家门店。已投入运行的应用系统多达两位数，各个系统实现技术及基础设施的运行要求都各不相同。 ")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("问题需迅速应对")]),_c('div',{staticClass:"content"},[_vm._v("运维人员数量较少，对事件的响应速度较慢，譬如关键设备故障、库存失衡和收银系统故障等。")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("澄清问题")]),_c('div',{staticClass:"content"},[_vm._v("门店花费大量时间与运维沟通澄清问题的细节，但还是经常导致运维理解产生偏差，拖延了解决进程。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("运维端及用户端")]),_c('div',{staticClass:"title2"},[_vm._v("通过以 Vticket 为平台，实现IT人员、信息系统以及内部用户的全联接。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("AI智能客服")]),_c('div',{staticClass:"title2"},[_vm._v("当店员遇到问题时，可以直接通过智能客服迅速获取解决方案，减少对IT部门的依赖。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("远程协助")]),_c('div',{staticClass:"title2"},[_vm._v("通过远程协助功能，IT部门迅速定位门店的各种故障原因，实时在线为门店提供精准的解决方案。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("自助解决率显著提升，反馈到IT部门的系统和设备的故障率下降 33%。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("问题的响应速度提高了 46%。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("IT人员前往门店的频率从 周/次 缩短到了 月/次 。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("建立便捷的工单流转机制来帮助IT部门、运维工程师快速有效的处理故障，降低由故障带来的损失。")])])])
}]

export { render, staticRenderFns }